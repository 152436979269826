<template>
  <div id="app">
    <Home />
  </div>
</template>

<script>
// import Home from './views/Home.vue'
    const Home = () => import("./views/Home.vue");

// import {mapState} from 'vuex';
export default {
  components:{Home},
  name: "App",
  mounted(){
    // this.setup()

  },
  computed:{
    // ...mapState(['accounts'])
  },
  methods:{
    async setup() {
      // await this.$store.dispatch("loadAccounts")
    }
  },
}
</script>

<style>

</style>
