
import Vue from 'vue'
import Vuex from 'vuex'
import Web3 from 'web3';
const data = require('../pdist.json');
const web3 = new Web3(Web3.givenProvider);

// var web3 = new Web3(new Web3.providers.HttpProvider('HTTP://127.0.0.1:8545'));
// var web3 = new Web3(Web3.givenProvider );
import {Contract} from '../pcontract'
import {Token} from '../pcontract'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    availableTokens:[],
    claimAccountNativeBalance:0,
    cAddress:Contract.options.address,
    owner: null,
    admin: null,
    symbol:null,
    decimals:0,
    accounts:[],
    claimData:data,
    claimAccountBalance:null,
    TokenBalance:0,
    isClaimDone:false,
    TokenName:null,
    claimable:false,
    tName:null,
  },
  mutations: {
  },
  actions: {
    loadSymbol: async(context) => {
      await Token.methods.decimals().call().then((res) => {
        context.state.decimals = res 
      })
      await Token.methods.symbol().call().then((res) => {context.state.symbol = res })
    },
    withdrawNative: async (context, data) => {
      // let resp = await Contract.methods.withdraw().send(
      //   {
      //     from:data.sender,
      //     gas: 210000,
      //   });
      // console.log(resp)
      // return;

      let  dta  = await Contract.methods.withdraw(
        // context.state.claimData.claims[account]["index"],
        //  account,
          // context.state.claimData.claims[account]["amount"],
          //  context.state.claimData.claims[account]["proof"]
           
           ).encodeABI()
      console.log(dta)  
      
      try {
        web3.eth.sendTransaction({
          from: data.sender,
          to: Contract.options.address,
          gas: '210000',
          data:dta})
          .on('transactionHash', function(hash){
          })
          .on('receipt', function(receipt){
            context.dispatch("contractBalance")
            alert('Claim Transaction submitted successfully')   

            // context.dispatch('BalanceCheck', account)
          })
          .on('error', (error) => {
            console.log("errir in claim", error)
          });
        return true
      } catch (error) {
        console.log(error);
        alert('error in submitting transaction')
        return false;
      }
    },
    withdrawNativeNew: async (context, data) => {
      
      try {
        await Contract.methods.withdraw().send({from:data.sender}).then((res) => {
          console.log("response of the withdraw function", res) 
        })
      
      } catch (error) {
        console.log(error);
        alert('error in submitting transaction')
        return false;
      }
    },
    updateAdmin: async (context, data) => {
      try {
        let resp = await Contract.methods.setAdmin(data.wallet).send({from:data.sender});
        console.log(resp);
        alert('admin wallet updated')  
      } catch (error) {
        console.log(error);
        alert('failed to update admin wallet')
      }
    },
    getOwner: async (context) => {
      await Contract.methods.owner().call().then((res) => {context.state.owner = res })
    },
    getAdmin: async (context) => {
      await Contract.methods.admin().call().then((res) => {context.state.admin = res })
    },
    withdraw: async(context, data) => {
      // console.log(data);
      // return;
      let amt = (Number(data.amount) *10**18).toString(16)
      amt = web3.utils.toBN("0x"+amt )
      console.log("amt is", amt)
      let  dta  = await Contract.methods.transferERC20(
        data.token ? data.token : Token.options.address,
        amt
           ).encodeABI()
      console.log(dta)  
      try {
          web3.eth.sendTransaction({
            from: data.sender,
            to: Contract.options.address,
            gas: '210000',
            data:dta
        })
        .on('transactionHash', function(hash){
        })
        .on('receipt', function(receipt){
          alert('Withdraw Transaction submitted successfully')    
          context.dispatch('BalanceCheck', data.sender)
        })
        .on('error', (error) => {
          console.log("errir in claim", error)
        });
      }catch(error){
        console.log('error of transaction',error)
        alert("transaction failed")
      }
      // let amt = (data.amount *10**18).toString(16)
      // amt = web3.utils.toBN("0x"+amt )
      // console.log("amt is", amt)

      // try {
      //   let resp = await Contract.methods.transferERC20(
      //     Token.options.address,
      //     amt)
      //     .send({from:data.sender});
      //   console.log(resp);
      //   alert('Admin Withdrawal Transaction submitted successfully')  
      //   return true
      // } catch (error) {
      //   console.log(error);
      //   alert('error in submitting transaction')
      //   return false;
      // }
      
    },
    withdrawFunds: async(context, data) => {
      console.log(data);
      // return;
      try {
        let resp = await Contract.methods.transferERC20(
          data.token,
          String(web3.utils.toWei(data.amount)) )
          .send({from:data.sender});
        console.log(resp);
        alert('Admin Withdrawal Transaction submitted successfully')  
        return true
      } catch (error) {
        console.log(error);
        alert('error in submitting transaction')
        return false;
      }
      
    },
    submitTransaction: async (
      context,account 
      ) => {
    let  dta  = await Contract.methods.claim(
      context.state.claimData.claims[account]["index"],
       account,
        context.state.claimData.claims[account]["amount"],
         context.state.claimData.claims[account]["proof"]).encodeABI()
    console.log(dta)  
    try {
        web3.eth.sendTransaction({
          from: account,
          to: Contract.options.address,
          gas: '210000',
          data:dta
      })
      .on('transactionHash', function(hash){
      })
      .on('receipt', function(receipt){
        alert('Claim Transaction submitted successfully')    
        context.dispatch('BalanceCheck', account)
      })
      .on('error', (error) => {
        console.log("errir in claim", error)
      });
    
      } catch (e) {
        alert("Error Occured :(")
        console.log(e)
        return e
      }

},
    Claim : async (context, account) => {
      
      try {
      await context.dispatch('submitTransaction', account)
      // context.state.TokenBalance += parseInt(context.state.claimData.claims[account]['amount'],16)/1000000000000000000;
      } catch (e) {
         alert("Your Address is Not In This Campaign");
         console.log('error', e);
         return e
       }
     
},
    hasClaimed : async (context, data) => {
      console.log(data.account,context.state.claimData.claims)
      if(context.state.claimData.claims[data.account]){
      try {
      let claimCheck = await Contract.methods.isClaimed(context.state.claimData.claims[data.account]["index"]).call();
           if(!claimCheck) await context.dispatch("Claim", data.account); else alert("This Address Has Already Claimed "+data.value+" GT");
     
           } catch (e) {
             console.log(e)
             return e
           }
         }
     
      else {alert("Connect Metamask or Address not Found")}
     
     },
    claim: async function (context, account) {
      const transactionParameters = {
        to: Contract.options.address, // Required except during contract publications.
        from: account, // must match user's active address.
        data: Contract.methods.claim(
          context.state.claimData.claims[account]["index"],
           account, 
           context.state.claimData.claims[account]["amount"],
            context.state.claimData.claims[account]["proof"])
            .encodeABI(),
      };
          console.log('transactions info',transactionParameters,context.state.claimData.claims[account]["amount"])
      try {
        await web3.eth.setProvider(Web3.givenProvider);

        const txHash = await window.ethereum.request({
          method: "eth_sendTransaction",
          params: [transactionParameters],
        });
          return txHash
          } catch (e) {
            alert("Error Occured :(")
            console.log(e)
            return e
          }
    },
    contractBalance: async function(context){
      Token.methods.balanceOf(Contract.options.address).call()
    .then(async (res) => {
      console.log('response of the balance check for clain contract is',(res/1000000000000000000))
      context.state.claimAccountBalance = (res/1000000000000000000)
      await web3.eth.setProvider(Web3.givenProvider);
      let dec = await web3.eth.getBalance(Contract.options.address)
      context.state.claimAccountNativeBalance = (dec/1000000000000000000)



    });

      // context.state.claimAccountBalance = balance/1000000000000000000
    },
    fundEth: async function(context) {
      console.log('sending transaction')
      // var web3 = await new Web3(Web3.givenProvider );
      web3.eth.sendTransaction({
        from: data,
        to: Contract.options.address,
        value: String(web3.utils.toWei('0.1'))
      })
      .on('transactionHash', function(hash){
        console.log('transactionHash', hash)
      })
      .on('receipt', function(receipt){
        console.log('transactionreceipt', receipt)
      })
      .on('confirmation', function(confirmationNumber, receipt){ 
        console.log('transactionconfirmation', confirmationNumber,receipt )
      })
      .on('error', (error) => console.log(error));
    },
    fundContract: async function (context, data) {

      try {
  await web3.eth.setProvider(Web3.givenProvider);
        let amt = data.amount;
        const resp = await Token.methods.transfer(
          Contract.options.address,
           web3.utils.toWei(amt.toString())).send({
          from:ethereum.selectedAddress,
          gas: '900000'
        });
        console.log('Responce of withdraw:', resp);
        return {success: true, data: resp};
      } catch(e) {
        console.log(e)
      }
    

    },
    checkClaimStatus: async (context, address) => {
      let claimCheck = await Contract.methods.isClaimed(context.state.claimData.claims[address]["index"]).call()
      return claimCheck;
    },
          BalanceCheck : async (context, address) => {
            console.log('address for checking balance and claim status', address,context.state.claimData.claims[address])
            try{
              let HXYbalance = await Token.methods.balanceOf(address).call();
              context.state.TokenBalance = HXYbalance/1000000000000000000;
            }
             catch(e) {
               console.log(e)
            }
            try{
              let claimCheck = await Contract.methods.isClaimed(context.state.claimData.claims[address]["index"]).call()
              context.state.isClaimDone = claimCheck;
            }
             catch(e) {
               console.log(e)
              //  alert('error in checking claim status')
            }
          },


    loadAccounts: async(context) => {
      // var web3 = await new Web3(new Web3.providers.HttpProvider('HTTP://127.0.0.1:8545'));
      let accounts = await web3.eth.getAccounts()
      context.state.accounts = accounts
      let balance = await web3.eth.getBalance(accounts[0])
      console.log(balance/1000000000000000000)
    },
    Bquery: async (context) => {
      const query = `
  {
    ethereum(network: bsc) 
    {
      address(address: {is: "${Contract.options.address}"}) 
      {
        balances 
        {
          value
          currency 
          {
            symbol
            address
          }
        }
      }
    }
  }
`;
const url = "https://graphql.bitquery.io/";
const opts = {
    method: "POST",
    headers: {
        "Content-Type": "application/json",
      "X-API-KEY": "BQY4bEr7mMfCOlh8BweyCgrcdSFYQ5fr"
    },
    body: JSON.stringify({
        query
    })
};
try {
  fetch(url, opts)
    .then(res => res.json())
    .then((res) => { 
      console.log("Bitquery Resp:", res)
      context.state.availableTokens = res.data.ethereum.address[0].balances
     })
    .catch(console.error);  
} catch (error) {
  console.log("Bitquery Error:", error)
}

    }
  },
  modules: {
  }
})
